<template>
    <div class="ui relaxed divided list">
        <div style="text-align:right;">
            <button v-if="!viewDone" class="ui compact mini labeled icon button" v-on:click="viewDone = true">
                <i class="eye basic icon"></i>
                erledigt
            </button>
            <button v-else class="ui compact mini labeled icon button" v-on:click="viewDone = false">
                <i class="eye slash basic icon"></i>
                erledigt
            </button>
        </div>
        <div class="ui tiny message">
            <i class="redo icon"></i> {{ lastUpdate }}
        </div>
        <div class="ui negative message" v-if="errorMessage">
            <div class="header">
                {{ errorMessage }}
            </div>
            <p>Beim Abruf der Wortmeldeliste vom HV-Portal ist ein Fehler aufgetreten. Bitte prüfe die Zugangsdaten und Erreichbarkeit des HV-Portals.</p>
        </div>
        <SpeakerListItem v-for="wortmeldung in filteredWList" :key="wortmeldung.id" :statusDB="statusDB" :obj="wortmeldung" :meetings="meetings" @doCall="call" :viewOnly="viewOnly" :fullscreenMode="fullscreenMode"></SpeakerListItem>
    </div>
</template>

<script>
import _ from 'lodash'

import { Logger } from 'aws-amplify'
import { API, graphqlOperation } from '@aws-amplify/api'

import { wmStatus } from '../graphql/queries'
import { onUpdateVideocallBreukelwmstatus, onCreateVideocallBreukelwmstatus } from '../graphql/subscriptions'

import SpeakerListItem from './SpeakerListItem'

const logger = new Logger('SpeakerList.vue')

export default {
    props: {
      breukel: Object,
      meetings: Array,
      viewOnly: Boolean,
      fullscreenMode: Boolean
    },

    data() {
        return {
            wortmeldungen: [],
            lastUpdate: '',
            timer: '',
            statusDB: [],
            subscriptions: [],
            viewDone: false,
            errorMessage: null
        }
    },

    computed: {
        filteredWList() {
            var self = this
            // Iterate over first array of objects
            var ret = _.clone(this.wortmeldungen)
            
            // filter active only
            if(!this.viewDone)
                ret = _.filter(ret, function(o) { return o.obj.wortmeldung })

            // filter last entry per user
            ret = _.reverse(_.uniqWith(_.reverse(ret), ({ aid: a }, { aid: b }) => a === b))

            // merge with local status database
            ret = _.map(ret, function(obj) {
                return _.assign(obj, _.find(self.statusDB, {eingabeid: obj.id}))
            })

            return ret
//            return _.reverse(_.uniqWith(_.reverse(_.filter(_.clone(this.wortmeldungen), function() { return true })), ({ aid: a }, { aid: b }) => a === b))
//            return _.reverse(_.uniqWith(_.reverse(_.filter(_.clone(this.wortmeldungen), function(o) { return o.obj.wortmeldung })), ({ aid: a }, { aid: b }) => a === b))
        }
    },

    methods: {
        breukelParser() {
            let self = this;
            // Simple GET request using fetch
            fetch("https://ktmqsj8j7g.execute-api.eu-central-1.amazonaws.com/prod/wm/breukel?url=" + encodeURIComponent( this.breukel.url + "GetData?gesid=" + this.breukel.gesid + "&passkey=" + this.breukel.passkey + "&typ=M"))
                .then(response => response.json())
                .then(data => {
                    if(data.message) {
                        this.errorMessage = data.message
                    } else {
                        this.errorMessage = null
                    
                        this.lastUpdate = new Date()
                        this.flush();
                        _.each(data, function(e) {
                            let newobj = {
                                name: e.nutzer + " (" + e.aktionaer + ")",
                                id: e.eingabeid,
                                aid: e.anmeldename,
                                nr: e.lfdnr,
                                email: e.absender_email,
                                phone: e.absender,
                                type: 'breukel',
                                inviteurl: "https://ktmqsj8j7g.execute-api.eu-central-1.amazonaws.com/prod/wm/breukel?url=" + encodeURIComponent( self.breukel.url + "SetData?gesid=" + self.breukel.gesid + "&passkey=" + self.breukel.passkey + "&typ=M" + "&guid=" + e.eingabeid + "&field=konferenzid&value="),
                                deleteurl: "https://ktmqsj8j7g.execute-api.eu-central-1.amazonaws.com/prod/wm/breukel?url=" + encodeURIComponent( self.breukel.url + "SetData?gesid=" + self.breukel.gesid + "&passkey=" + self.breukel.passkey + "&typ=M" + "&guid=" + e.eingabeid + "&field=wortmeldung&value=0"),
                                obj: e
                            }
                            if(!e.nutzer) newobj.name = e.aktionaer
                            self.wortmeldungen.push(newobj)
                        })
                
                    }
                });
        },

        call(e) {
            this.$emit("doCall",e)
        },

        flush() {
            this.wortmeldungen.splice(0);
        },

        loadData() {
            if(this.breukel && this.breukel.gesid) {
                API.graphql(graphqlOperation(wmStatus,  { gesellschaftid: this.breukel.gesid }))
                .then(res => {
                    if(res.data && res.data.listVideocallBreukelwmstatuses)
                        this.statusDB = res.data.listVideocallBreukelwmstatuses.items
                })
                .catch(err => logger.debug('graphql error', err))
            }
        }
    },

    created() {
        this.loadData()
    },


    mounted() {
        if(this.breukel) {
            this.breukelParser()
            this.timer = setInterval(this.breukelParser, 1000);
        }

        // Subscribe to change of event
        this.subscriptions.push = API.graphql(
            graphqlOperation(onCreateVideocallBreukelwmstatus,  { gesellschaftid: this.breukel.gesid })
        ).subscribe({
            next: (action) => {
                    logger.debug(action)
                    if(action.value && action.value.data && action.value.data.onCreateVideocallBreukelwmstatus) {
                        this.statusDB.push(action.value.data.onCreateVideocallBreukelwmstatus)
                    }
                },
            error: (error) => logger.debug(error)
        })

        this.subscriptions.push = API.graphql(
            graphqlOperation(onUpdateVideocallBreukelwmstatus,  { gesellschaftid: this.breukel.gesid })
        ).subscribe({
            next: (action) => {
                    logger.debug(action)
                    if(action.value && action.value.data && action.value.data.onUpdateVideocallBreukelwmstatus) {
                        _.remove(this.statusDB, function(currentObject) {
                            return currentObject.eingabeid === action.value.data.onUpdateVideocallBreukelwmstatus.eingabeid;
                        })
                        this.statusDB.push(action.value.data.onUpdateVideocallBreukelwmstatus)
                    }
                },
            error: (error) => logger.debug(error)
        })
    },

    beforeUnmount () {
        logger.debug("clearInterval polling");
        clearInterval(this.timer);
        // unsubscribe all subscriptions
        logger.debug("unsubscribe everything");
        for(var i = 0 ; i < this.subscriptions.length ; i++)
            this.subscriptions[i].unsubscribe()
    },

    components: {
        SpeakerListItem
    }
}
</script>
