<template>
    <div class="flexelement">
        <div>{{ eventslot.name && eventslot.name.length > 1 ? eventslot.name : '-' }}</div>
    </div>
</template>

<script>
  //import { Logger } from 'aws-amplify'

  //const logger = new Logger('LowerThirdElement.vue')
  
  export default {
    props: {
      eventslotid: Number,
      eventslot: Object,
      eventobj: Object,
      meta: Object
    },

    data() {
      return {
      }
    },
  }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
    .flexelement {
        flex-grow: 1;
        align-self: auto;
        text-align: center;
        border-top: 1px solid #333;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 8vh;
        font-weight: 700;
        font-family: 'Roboto Condensed', sans-serif;
    }
</style>