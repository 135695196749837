<template>
    <div class="ui card" style="width:340px;">
        <div class="content">
            <div v-if="eventslot.token" class="right floated meta"><a><i class="eraser icon" v-on:click="cleanSlot(eventslotid)"></i></a></div>
            <div class="header"><a class="ui circular large label" style="margin-right:1em;">{{ eventslotid + 1 }}</a> {{ eventslot.name && eventslot.name.length > 1 ? eventslot.name : '[empty]' }}</div>
            
            <div class="meta" v-if="eventobj.mail.notificationType == 'breukelapi'">
                <i class="icon" :class="statusIcon[eventslot.status] + ' ' + statusColor[eventslot.status] + ' '"></i>
                {{ statusText[eventslot.status] }}
            </div>
            <div class="meta" v-else>{{ eventslot.username && eventslot.username.length > 1 ? eventslot.username : '-' }}</div>

            <div class="description">
                <p>
                    <span class="ui image label" v-if="eventslot.token">
                        <i class="lock icon"></i> {{ eventslot.token }}
                    </span>
                </p>
                <br/>
                <p>
                    <a class="ui image label" v-if="eventslot.locator && eventslot.username && eventslot.token" :href="userMeetingLink(eventslotid, eventslot)" target="_blank">
                        <i class="chrome icon"></i> User Link
                    </a>
                </p>
                <p>
                    <a class="ui image label" v-if="eventslot.locator" :href="eventslot.locator" target="_blank">
                         <img src="../assets/teams.svg" style="padding: 0.4em 0em 0.5em 0.5em;">
                        Teams Meeting Link
                    </a>
                </p>
            </div>
        </div>
        <div class="extra content">
            <span class="right floated" v-if="eventobj.mail.notificationType == 'breukelapi'">
                <span v-if="eventslot.wmid === '' || !eventslot.wmid">-</span>
                <button v-if="eventslot.status === 'invited' || eventslot.status === 'failed'" class="ui orange right labeled icon button" v-on:click="setStatus('checking')"><i class="arrow right icon"></i>in Prüfung</button>
                <button v-if="eventslot.status === 'checking'" class="ui red left labeled icon button" v-on:click="setStatus('failed')"><i class="times icon"></i>fehlgeschlagen</button>
                <button v-if="eventslot.status === 'checking'" class="ui green right labeled icon button" v-on:click="setStatus('ready')"><i class="arrow right icon"></i>bereit</button>
                <button v-if="eventslot.status === 'ready'" class="ui orange right labeled icon button" v-on:click="setStatus('checking')"><i class="arrow left icon"></i>zur Prüfung</button>
                <button v-if="eventslot.status === 'ready'" class="ui teal right labeled icon button" v-on:click="setStatus('live')"><i class="arrow right icon"></i>spricht</button>
                <button v-if="eventslot.status === 'live'" class="ui red right labeled icon button" v-on:click="setStatus('failed')"><i class="arrow left icon"></i>fehlgeschlagen</button>
                <button v-if="eventslot.status === 'live'" class="ui green right labeled icon button" v-on:click="setStatus('done')"><i class="check icon"></i>erledigt</button>
            </span>

            <span class="right floated" v-else>
                <div v-if="eventslot.mailsend">
                    <button class="ui teal icon button" v-if="eventobj.mail.notificationType == 'api' && eventslot.email && eventslot.locator && eventslot.username && eventslot.token && eventslot.name" v-on:click="sendAPIpush(eventslotid, eventslot)"><i class="bell icon"></i></button>
                    <button class="ui teal icon button" v-if="eventobj.mail.notificationType == 'email' && eventslot.email && eventslot.locator && eventslot.username && eventslot.token && eventslot.name" v-on:click="sendMailSES(eventslotid, eventslot)"><i class="mail icon"></i></button>
                    <button class="ui teal icon button" v-if="eventobj.mail.notificationType == 'mailto' && eventslot.email && eventslot.locator && eventslot.username && eventslot.token && eventslot.name" v-on:click="sendMailExternal(eventslotid, eventslot)"><i class="external alternate icon"></i></button>

                    <button class="ui positive basic button">
                        <i class="icon check"></i>
                        {{ mailTimestamp }}
                    </button>
                </div>
                <div v-else>
                    <button class="ui labeled teal icon button" v-if="eventobj.mail.notificationType == 'api' && eventslot.email && eventslot.locator && eventslot.username && eventslot.token && eventslot.name" v-on:click="sendAPIpush(eventslotid, eventslot)"><i class="bell icon"></i>Einladung senden</button>
                    <button class="ui labeled teal icon button" v-if="eventobj.mail.notificationType == 'email' && eventslot.email && eventslot.locator && eventslot.username && eventslot.token && eventslot.name" v-on:click="sendMailSES(eventslotid, eventslot)"><i class="mail icon"></i>Einladung senden</button>
                    <button class="ui labeled teal icon button" v-if="eventobj.mail.notificationType == 'mailto' && eventslot.email && eventslot.locator && eventslot.username && eventslot.token && eventslot.name" v-on:click="sendMailExternal(eventslotid, eventslot)"><i class="external alternate icon"></i>Einladung senden</button>
                </div>
                <button class="ui labeled icon button" v-if="!eventslot.email || !eventslot.locator || !eventslot.username || !eventslot.token || !eventslot.name" v-on:click="openNewUserForm(eventslotid, eventslot)"><i class="plus icon"></i> Teilnehmer anlegen</button>
            </span>

            <span>
                <i v-if="meta" style="padding: 0.6em 0 0 0;" class="icon green check"></i>
            </span>
        </div>
    </div>
</template>

<script>
  /* global moment */

  import { Logger } from 'aws-amplify'
  import { API, graphqlOperation } from '@aws-amplify/api'

  import { updateEventSlots, createVideocallActivityLog, createBWMstatus, updateBWMstatus } from '../graphql/mutations'

  const logger = new Logger('EventSlot.vue')

  export default {
    props: {
      eventslotid: Number,
      eventslot: Object,
      eventobj: Object,
      meta: Object
    },

    data() {
      return {
        statusIcon: {
            'invited':  'paper plane',
            'connecting': 'hourglass start',
            'calling':  'phone',
            'checking': 'search',
            'ready':    'check',
            'failed':   'times',
            'done':     'check',
            'live':     'microphone'
        },

        statusText: {
            'invited':  'Einladung versendet',
            'connecting': 'Verbindung wird aufgebaut',
            'calling':  'im Meeting',
            'checking': 'in Prüfung',
            'ready':    'bereit',
            'failed':   'Vorpüfung fehlgeschlagen',
            'done':     'erledigt',
            'live':     'spricht gerade'
        },

        statusColor: {
            'invited':  'grey',
            'connecting': 'black',
            'calling':  'yellow',
            'checking': 'orange',
            'ready':    'green',
            'failed':   'red',
            'done':     'olive',
            'live':     'teal'
        }
      }
    },

    computed: {
        mailTimestamp() {
            return moment(this.eventslot.mailsend).format("DD.MM.YY HH:mm:SS")
        }
    },

    methods: {
        setStatus(newStatus) {
            let slots = JSON.parse(this.eventobj.slots)
            slots[this.eventslotid].status = newStatus
            let outSlots = JSON.stringify(slots)

            if(this.eventobj.api && this.eventobj.api.breukel && this.eventobj.api.breukel.gesid)
                this.setStatusBreukel(newStatus, this.eventobj.api.breukel.gesid)

            API.graphql(graphqlOperation(updateEventSlots, {
              id: this.$route.params.id,
              slots: outSlots,
            })).then(res => {
                if(res.data && res.data.updateClient) {
                  logger.debug('graphql update', res)
                }
              })
              .catch(err => logger.debug('graphql error', err))
        },

        setStatusBreukel(newStatus, gesID) {
            API.graphql(graphqlOperation(updateBWMstatus, {
                eingabeid: this.eventslot.wmid,
                gesellschaftid: gesID,
                wmstatus: newStatus,
                timestamp: new Date()
            })).then(res => {
                if(res.data) {
                  logger.debug('graphql update', res)
                }
              })
              .catch(err => {
                logger.debug('graphql update error, try to create new item', err)
                API.graphql(graphqlOperation(createBWMstatus, {
                    eingabeid: this.eventslot.wmid,
                    gesellschaftid: gesID,
                    wmstatus: newStatus,
                    timestamp: new Date()
                })).then(res => {
                    if(res.data) {
                    logger.debug('graphql create', res)
                    }
                })
                .catch(err => logger.debug('graphql create error', err))
            })
        },

        cleanSlot(key) {
            let slots = JSON.parse(this.eventobj.slots)

            slots[key].name = ""
            slots[key].username = ""
            slots[key].email = ""
            slots[key].token = ""
            slots[key].mailsend = ""

            if(slots[key].wmid) slots[key].wmid = ""
            if(slots[key].status) slots[key].status = ""
            if(slots[key].phone) slots[key].phone = ""

            let outSlots = JSON.stringify(slots)

            API.graphql(graphqlOperation(updateEventSlots, {
              id: this.$route.params.id,
              slots: outSlots,
            })).then(res => {
                if(res.data && res.data.updateClient) {
                  logger.debug('graphql update', res)
                }
              })
              .catch(err => logger.debug('graphql error', err))

            // cleanup teams meeting revoke tokens
            let apiName = 'VideoCall Backend'
            let path = '/azure/cleanslot'
            let myInit = {
                body: { id: this.eventobj.id + "-" + key },
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            }

            API.post(apiName, path, myInit).then(response => {
                logger.debug('cleanupTeamsMeeting', response)
            }).catch(error => {
                logger.debug(error.response)
            })
        },

        userMeetingLink(key, slot) {
            let token = {
                event: this.$route.params.id,
                slot: ""+key,
                token: slot.token
            }
            
            let tokenB64 = encodeURI(btoa(JSON.stringify(token)))
            return 'https://videocall.streaming-solution.de/?token=' + tokenB64
        },

        openNewUserForm() {
            this.$emit('openNewUserForm', this.eventslotid, this.eventslot)
        },

        generateMailText(key, slot, text) {
            let generatedText = "" + text
            generatedText = generatedText.replaceAll('{{meetinglink}}', this.userMeetingLink(key, slot))
            generatedText = generatedText.replaceAll('{{teamslink}}', slot.locator)
            generatedText = generatedText.replaceAll('{{username}}', slot.username)
            generatedText = generatedText.replaceAll('{{name}}', slot.name)

            return generatedText
        },

        setMailMarker() {
            let slots = JSON.parse(this.eventobj.slots)

            slots[this.eventslotid].mailsend = new Date().toISOString()

            let outSlots = JSON.stringify(slots)

            API.graphql(graphqlOperation(updateEventSlots, {
            id: this.$route.params.id,
            slots: outSlots,
            })).then(res => {
                if(res.data && res.data.updateClient) {
                logger.debug('graphql update', res)
                }
            })
            .catch(err => logger.debug('graphql error', err))
        },

        sendAPIpush(key, slot) {
            // TODO api push zu hv portal
            logger.debug("sendAPIpush", key, slot)
            this.dbLogger("sendinvite", "Einladung als API push an die HV Plattform gesendet")

            this.setMailMarker()
        },

        sendMailSES(key, slot) {
            let mailData = {
                recipient: slot.email,
                subject: this.generateMailText(key, slot, this.eventobj.mail.subject),
                text: this.generateMailText(key, slot, this.eventobj.mail.body),
                html: this.generateMailText(key, slot, this.eventobj.mail.html)
            }

            if(this.eventobj.mail.bcc) mailData.bcc = this.eventobj.mail.bcc
            if(this.eventobj.mail.cc) mailData.cc = this.eventobj.mail.cc

            let apiName = 'VideoCall Backend'
            let path = '/ses'
            let myInit = {
                body: mailData,
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            }

            let self = this

            API.post(apiName, path, myInit).then(response => {
                if(response.errorType) {
                    self.dbLogger("error", "AWS SES Fehler: " + JSON.stringify(response.errorType))
                }

                if(response.$metadata && response.$metadata.httpStatusCode == 200) {
                    self.dbLogger("sendinvite", "Einladung wurde per AWS SNS an " + mailData.recipient + " gesendet")
                }
                
            }).catch(error => {
                //logger.debug(error.response)
                self.dbLogger("error", "AWS SES Fehler: " + JSON.stringify(error.response))
            })

            this.setMailMarker()
        },

        sendMailExternal(key, slot) {
            if(this.eventobj.mail.bcc)
                window.location = "mailto:" + slot.email + "?bcc=" + this.eventobj.mail.bcc + "&subject=" + encodeURI(this.generateMailText(key, slot, this.eventobj.mail.subject)) + "&body=" + encodeURI(this.generateMailText(key, slot, this.eventobj.mail.body))
            else
                window.location = "mailto:" + slot.email + "?&subject=" + encodeURI(this.generateMailText(key, slot, this.eventobj.mail.subject)) + "&body=" + encodeURI(this.generateMailText(key, slot, this.eventobj.mail.body))
        
            this.setMailMarker()
            this.dbLogger("sendinvite", "Einladung wurde an externes eMail-Programm übergeben")
        
        },

        dbLogger(event, info) {
            API.graphql(graphqlOperation(createVideocallActivityLog, {
              slotid: this.eventobj.id + "-" + this.eventslotid,
              slottoken: this.eventslot.token,
              action: event,
              info: info
            })).then(res => {
                logger.debug("dbLogger graphql", res.data)
            }).catch(err => logger.debug('dbLogger graphql error', err))
        }
    },

    components: {
    }
  }
</script>