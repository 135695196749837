// eslint-disable
// this is an auto generated file. This will be overwritten

export const listEventsAll = `query listVideocallEvents {
  listVideocallEvents {
    nextToken
    items {
      slots
      name
      description
      id
      datum
      archive
    }
  }
}
  `;

export const listEvents = `query listVideocallEvents {
    listVideocallEvents(filter: {archive: {ne: true}}) {
      nextToken
      items {
        slots
        name
        description
        id
        datum
        archive
      }
    }
  }
    `;

export const listEventsArchive = `query listVideocallEvents {
    listVideocallEvents(filter: {archive: {eq: true}}) {
      nextToken
      items {
        slots
        name
        description
        id
        datum
        archive
      }
    }
  }
    `;
  

export const getEvent = `query getVideocallEvent($id: ID!) {
    getVideocallEvent(id: $id) {
      id
      name
      description
      datum
      mail
      api
      slots
      archive
    }
  }
  `;

export const listMeetings = `query listVideocallMeetings {
    listVideocallMeetings {
      nextToken
      items {
        id
        status
        user
      }
    }
  }
  `;

  export const wmStatus = `query listVideocallBreukelwmstatuses($gesellschaftid: String!) {
    listVideocallBreukelwmstatuses(filter: {gesellschaftid: {eq: $gesellschaftid}}) {
      nextToken
      items {
        eingabeid
        status
        gesellschaftid
        timestamp
      }
    }
  }
  `;