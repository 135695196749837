<template>
    <div class="view-events">
      <button v-if="!loading" v-on:click.prevent="create()" class="ui right labeled icon button right floated teal">
        <i class="add icon"></i>
        neue Veranstaltung
      </button>
  
  
      <h1>Veranstaltungen</h1>
      <loading-wheel v-if="loading"></loading-wheel>
  
      <div v-if="nodata">
          <NoDataFound name="Veranstaltungen"></NoDataFound>
      </div>
  
      <div v-else>
        
        <div class="ui segment unstackable items" v-for="event in sortedEvents" :key="event.id">
          <div class="item">
            <div class="image">
              <i class="huge users icon"></i>
            </div>
            <div class="content">
              <router-link :to="{'path': '/event/' + event.id}" class="header">{{ event.name }}</router-link>
              <div class="meta">{{ event.id }}</div>
              <div style="font-weight: bold;">{{ beautyDate(event.datum) }}</div>
              <div class="description">
                <p>{{ event.description }}</p>
              </div>
              <div>
                <div v-for="(slot, index) in JSON.parse(event.slots)" :key="index" class="ui label">
                  <i class="phone icon"></i>
                  {{ index+1 }}
                  <a class="detail">{{ slot.username }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button v-on:click.prevent="create()" class="ui right labeled icon button right floated teal">
          <i class="add icon"></i>
          neue Veranstaltung
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import _ from 'lodash'
  import moment from 'moment'

  moment.locale('de')

  import { Auth, Logger } from 'aws-amplify'
  import { API, graphqlOperation } from 'aws-amplify'
  import { listEvents } from '../graphql/queries'
  import { createEvent } from '../graphql/mutations'
  import { promiseWithTimeout } from '../promise-timeout'
  
  const logger = new Logger('Events.vue')
  
  import LoadingWheel from '../components/LoadingWheel'
  import NoDataFound from '../components/NoDataFound'
  
  export default {
    data() {
      return {
        events: [],
        loading: true,
        nodata: false
      }
    },

    computed: {
        sortedEvents() {
            return _.sortBy(this.events, 'datum');
        }
    },
      
    methods: {
      beautyDate(date) {
        let mdate = moment(date, 'YYYY-MM-DDZ')
        if(mdate > 0)
          return mdate.format("dddd, Do MMMM YYYY")
        return "-"
      },

      create() {
        API.graphql(graphqlOperation(createEvent, {
              name: "Neue Veranstaltung",
              datum: "2999-01-01Z"
            })).then(res => {
                if(res.data) {
                  logger.debug('graphql event created', res)
                  this.events.push(res.data.createVideocallEvent)
                }
              })
              .catch(err => { 
                logger.debug('graphql error', err)
            })
      },

      loadData() {
        let doIt = promiseWithTimeout(15000, API.graphql(graphqlOperation(listEvents)))
  
        // Wait for the promise to get resolved
        doIt.then(res => {
          logger.debug('data', res)
          if(res.data && res.data.listVideocallEvents) {
            this.events = res.data.listVideocallEvents.items
          }

          if( this.events.length === 0 )
            this.nodata = true

          this.loading = false
        })

        // Wait for the promise to get rejected or timed out
        doIt.catch(err => {
          logger.debug('graphql error', err)
          this.loading = false
          this.nodata = true
        })
      }
    },
  
    components: {
      LoadingWheel, NoDataFound
    },
  
    mounted () {
  
      // refresh current Session
      Auth.currentSession()
      .then(data => logger.debug(data))
      .catch(err => logger.debug(err));
  
      logger.debug('mounted')

      this.loadData()
    }
  }
  </script>
  
  <style>
    .ui.items > .item > .image {
      background: #bcbec0;
      text-align: center;
      line-height: 9em;
      color: #e7e8e9;
    }
  </style>
