<template>
    <div class="view-event">
        <loading-wheel v-if="loading"></loading-wheel>

        <div v-else><!-- headline -->
            <button v-if="!loading" v-on:click.prevent="openEditEventForm()" class="ui right labeled icon button right floated">
              <i class="edit icon"></i>
                bearbeiten
            </button>

            <router-link :to="'/lowerthird/' + $route.params.id" target="_blank" class="ui button black right floated">
                    <i class="info circle icon"></i>
                    Bauchbinden
            </router-link>
            
            <router-link :to="'/wortmeldeliste/' + $route.params.id" target="_blank" class="ui button right floated">
                    <i class="info circle icon"></i>
                    Wortmeldeliste
            </router-link>
            
            <h2 class="ui header">
                <i class="users icon"></i>
                <div class="content">
                    {{ event.name }}
                    <div class="sub header">{{ $route.params.id }}</div>
                </div>
            </h2>
            
            <div class="ui divided grid" v-if="(event.mail && event.mail.notificationType && event.mail.notificationType == 'breukelapi')">
                <div class="two column row">
                    <div class="ten wide column">
                        <SpeakerList v-if="event.api.breukel" :breukel="event.api.breukel" :meetings="JsonEventSlots" @doCall="callFromAPI"></SpeakerList>
                    </div>
                    <div class="six wide column">
                        <div class="ui cards">
                            <EventSlot v-for="(slot, key) in JsonEventSlots" :key="key" :eventslotid="key" :eventslot="slot" :eventobj="event" :meta="slotstatus[key]" v-on:openNewUserForm="openNewUserForm"></EventSlot>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="ui cards" v-else>
                <EventSlot v-for="(slot, key) in JSON.parse(event.slots)" :key="key" :eventslotid="key" :eventslot="slot" :eventobj="event" :meta="slotstatus[key]" v-on:openNewUserForm="openNewUserForm"></EventSlot>
            </div>

            <div v-if="event.archive" class="ui dimmer modals page transition visible active" style="display: flex !important;"><div class="ui small basic test modal transition visible active scrolling" style="display: block !important;">
                <div class="ui icon header">
                    <i class="archive icon"></i>
                        Archiviert
                    </div>
                    <div class="content">
                    <p>Diese Veranstaltung wurde archiviert. Solltest du diese Veranstaltung versehentlich archiviert haben kannst du Sie mit dem Button "wiederherstellen" wieder aus dem Archiv herstellen.</p>
                    </div>
                    <div class="actions">
                    <router-link :to="{'path':'/'}" class="ui red basic cancel inverted button">
                        <i class="remove icon"></i>
                        schließen
                    </router-link>
                    <div class="ui green ok inverted button" v-on:click="unArchive()">
                        <i class="upload icon"></i>
                        wiederherstellen
                    </div>
                    </div>
                </div>
            </div>

        </div>


        <!-- modals start -->
        <div class="ui dimmer modals visable active" v-if="modal">
            <!-- new meeting user / token -->
            <div class="ui active modal" v-if="newUserForm >= 0">
                <div class="header">Neuen VideoCall Teilnehmer anlegen</div>
                <div class="content">
                    <div class="ui form">
                        <h2>Slot No. {{ newUserForm }}</h2>
                        <div class="field">
                            <label>Name</label>
                            <input placeholder="Name" type="text" v-model="formContent.name">
                        </div>
                        <div class="field">
                            <label>Username in Meeting</label>
                            <input placeholder="Username" type="text" v-model="formContent.username">
                        </div>
                        <div class="field">
                            <label>eMail Adresse</label>
                            <input placeholder="eMail" type="text" v-model="formContent.email">
                        </div>
                        <div class="disabled field">
                            <label>Token</label>
                            <input placeholder="Auth Token" disabled="" type="text" v-model="formContent.token">
                        </div>
                    </div>
                </div>

                <div class="actions">
                    <div class="ui cancel button" v-on:click="closeNewUserForm()">abbrechen</div>
                    <div class="ui green cancel button" v-on:click="saveNewUserForm()">speichern</div>
                </div>
            </div>

            <!-- select room modal -->
            <div class="ui active modal" v-if="selectedSlot >= -1">
                <div class="header">
                    Wortmeldung einem Redneraum zuweisen
                </div>
                <div class="scrolling content">
                    <div class="ui link cards">
                        <div class="card" :class="{ disabled: slot.token, selected: (key == selectedSlot) }" v-for="(slot, key) in JSON.parse(event.slots)" :key="key" v-on:click="selectSlot(key)">
                            <div class="content center aligned">
                                <a class="ui circular label" :class="{ teal: !slot.token }" style="font-size:3em; margin-bottom:0.5em;">{{ key+1 }}</a>
                                <div class="header">{{ slot.name || "-" }}</div>
                                <div class="meta">
                                    <a>{{ slot.token || "-" }}</a>
                                </div>
                                <div class="description"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="actions">
                    <div class="ui cancel button" v-on:click="closeSelectRoom()">abbrechen</div>
                    <div class="ui green cancel button" v-on:click="sendAPIinvitation()">Einladung senden</div>
                </div>
            </div>

            <!-- edit event -->
            <div class="ui active modal" v-if="editEventForm">
                <div class="header">Veranstaltung bearbeiten</div>
                <div class="scrolling content">
                    <div class="ui form">
                        <h2 class="ui dividing header">Allgemein</h2>
                        <div class="field">
                            <label>Name</label>
                            <input placeholder="Name" type="text" v-model="formContent.name">
                        </div>
                        <div class="field">
                            <label>Datum</label>
                            <input placeholder="YYYY-MM-TTZ" type="text" v-model="formContent.datum">
                        </div>
                        <div class="field">
                            <label>Beschreibung</label>
                            <input placeholder="Beschreibung" type="text" v-model="formContent.description">
                        </div>

                        <h2 class="ui dividing header">Meetingeinladung</h2>

                        <div class="field">
                            <label>Art der Einladungszustellung</label>
                            <NotificationTypeDropdown :notificationType="formContent.mail.notificationType" @onChange="onChangeNotificationType"></NotificationTypeDropdown>
                        </div>
                        
                        <div v-if="(formContent.mail.notificationType == 'email' || formContent.mail.notificationType == 'mailto')">
                            <div class="field">
                                <label>BCC Empfänger</label>
                                <input placeholder="BCC Adresse" type="text" v-model="formContent.mail.bcc">
                            </div>
                            <div class="field">
                                <label>Betreffzeile</label>
                                <input placeholder="Betreff" type="text" v-model="formContent.mail.subject">
                            </div>
                            <div class="field">
                                <label>Text</label>
                                <textarea placeholder="eMail Text" v-model="formContent.mail.body"></textarea>
                            </div>
                            <div class="field">
                                <label>HTML</label>
                                <textarea placeholder="eMail Text" v-model="formContent.mail.html"></textarea>
                            </div>
                        </div>

                        <div v-else-if="(formContent.mail.notificationType == 'breukelapi')">
                            <div class="field">
                                <label>Portal URI (Pfad: Basisurl + "/hvs/" https://xxxx.yyy/hvs/)</label>
                                <input placeholder="Portal URI" type="text" v-model="formContent.api.breukel.url">
                            </div>
                            <div class="field">
                                <label>Gesellschaft ID</label>
                                <input placeholder="Gesellschaft ID" type="text" v-model="formContent.api.breukel.gesid">
                            </div>
                            <div class="field">
                                <label>API Passkey</label>
                                <input placeholder="API Passkey" type="text" v-model="formContent.api.breukel.passkey">
                            </div>
                        </div>

                        <div v-else>
                            Zustellverfahren der Aktionärseinladung nicht konfiguriert!<br>
                            >>> {{ formContent.mail.notificationType }}
                        </div>

                        <h2 class="ui dividing header">Meetingräume</h2>
                        <div class="ui middle aligned divided list">
                            <div class="item" v-for="(slot, key) in formContent.slots" :key="key">
                                <div class="right floated content">
                                  <div class="ui icon button" v-on:click="formContent.slots.splice(key,1)"><i class="x icon"></i></div>
                                </div>
                                <img class="ui avatar image" src="../assets/teams.svg" />
                                <div class="content">
                                    <div class="ui labeled input">
                                        <div class="ui label">
                                            {{ key }}
                                        </div>
                                        <input type="text" placeholder="Teams Meeting URL" v-model="slot.locator">
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="content" style="text-align: center;">
                                    <button class="ui icon button" v-on:click="formContent.slots.push({'locator':'', 'name':'', 'email':'', 'token':'', 'username':''})">
                                        <i class="plus icon"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="actions">
                    <div class="ui left floated labeled icon black button" v-on:click="archive()"><i class="archive icon"></i>archivieren</div>
                    <div class="ui cancel button" v-on:click="closeEditEventForm()">abbrechen</div>
                    <div class="ui green right labeled icon button" v-on:click="saveEditEventForm()"><i class="save icon"></i>speichern</div>
                </div>
            </div>
        </div>
        <!-- modals end -->

    </div>
</template>

<script>
  import Vue from 'vue' // eslint-disable-line no-unused-vars

  import { Logger } from 'aws-amplify'
  import { API, graphqlOperation } from '@aws-amplify/api'
  import { promiseWithTimeout } from '../promise-timeout'

  import { getEvent, listMeetings } from '../graphql/queries'
  import { updateEvent, updateEventSlots, createBWMstatus, updateBWMstatus, updateEventArchive } from '../graphql/mutations'
  import { onUpdateEvent, onCreateVideocallMeeting, onUpdateVideocallMeeting, onDeleteVideocallMeeting } from '../graphql/subscriptions'

  import LoadingWheel from '../components/LoadingWheel'
  import EventSlot from '../components/EventSlot'
  import NotificationTypeDropdown from '../components/NotificationTypeDropdown'
  import SpeakerList from '../components/SpeakerList'

  const logger = new Logger('Event.vue')

  const UUIDGeneratorBrowser = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

  export default {
    data() {
      return {
        event: null,
        slotstatus: [],
        meetings: null,
        modified: false, /* wenn daten verändert, speichern knopf aktivieren */
        loading: true,
        modal: false,
        editEventForm: false,
        newUserForm: -1,
        formContent: {},
        subscriptions: [],
        selectedSlot: -2
      }
    },

    computed: {
        JsonEventSlots() {
            return JSON.parse(this.event.slots);
        }
    },

    methods: {
        loadData() {
            this.loading = true

            logger.debug("route id", this.$route.params.id)

            API.graphql(graphqlOperation(getEvent,  { id: this.$route.params.id }))
            .then(res => {
                if(res.data && res.data.getVideocallEvent) {
                    // parse mail data
                    res.data.getVideocallEvent.mail = JSON.parse(res.data.getVideocallEvent.mail)
                    res.data.getVideocallEvent.api = JSON.parse(res.data.getVideocallEvent.api)
                    this.event = res.data.getVideocallEvent
                }
                this.modified = false
                this.loading = false
            })
            .catch(err => logger.debug('graphql error', err))

            // get metadata for meetingslots
            let doIt = promiseWithTimeout(15000, API.graphql(graphqlOperation(listMeetings)))
  
            // Wait for the promise to get resolved
            doIt.then(res => {
                if(res.data && res.data.listVideocallMeetings) {
                    let objs = res.data.listVideocallMeetings.items

                    for(var i = 0 ; i < objs.length ; i++)
                        this.modifySlotMetadata(objs[i])
                } else 
                    this.slotstatus = []
            })

            // Wait for the promise to get rejected or timed out
            doIt.catch(err => {
                logger.debug('graphql error', err)
                this.loading = false
                this.nodata = true
            })
            
        },

        archive() {
            logger.debug("archive", this.formContent)

            API.graphql(graphqlOperation(updateEventArchive, {
              id: this.$route.params.id,
              archive: true
            })).then(res => {
                if(res.data && res.data.updateVideocallEvent) {
                  logger.debug('graphql update', res)
                  this.event.archive = true
                }
              })
              .catch(err => { 
                logger.debug('graphql error', err)
            })

            this.editEventForm = false
            this.modal = false
        },

        unArchive() {
            logger.debug("archive", this.formContent)

            API.graphql(graphqlOperation(updateEventArchive, {
              id: this.$route.params.id,
              archive: false
            })).then(res => {
                if(res.data && res.data.updateVideocallEvent) {
                  logger.debug('graphql update', res)
                  this.event.archive = false
                }
              })
              .catch(err => { 
                logger.debug('graphql error', err)
            })   
        },

        openEditEventForm() {
            this.formContent = JSON.parse(JSON.stringify(this.event))
            this.initFormInvitationContent()

            this.formContent.slots = []

            if(this.event.slots && this.event.slots.length > 0)
                this.formContent.slots = JSON.parse(this.event.slots)
    
            logger.debug("this.formContent",this.formContent)

            this.editEventForm = true
            this.modal = true
        },

        initFormInvitationContent() {
            if(!this.formContent.mail) this.formContent.mail = {}
            if(!this.formContent.mail.bbc) this.formContent.mail.bbc = ''
            if(!this.formContent.mail.body) this.formContent.mail.body = ''
            if(!this.formContent.mail.html) this.formContent.mail.html = this.formContent.mail.body
            if(!this.formContent.mail.subject) this.formContent.mail.subject = ''
            if(!this.formContent.mail.notificationType) this.formContent.mail.notificationType = ''

            if(!this.formContent.api) this.formContent.api = {}

            //if(this.formContent.mail.notificationType == "breukelapi") {
                if(!this.formContent.api.breukel) this.formContent.api.breukel = {}
                if(!this.formContent.api.breukel.url) this.formContent.api.breukel.url = ""
                if(!this.formContent.api.breukel.gesid) this.formContent.api.breukel.gesid = ""
                if(!this.formContent.api.breukel.passkey) this.formContent.api.breukel.passkey = ""  
            //}
        },

        onChangeNotificationType(newValue) {
            this.formContent.mail.notificationType = newValue
            this.initFormInvitationContent()
            this.$forceUpdate()
            logger.debug("onChangeNotificationType", this.formContent.api)
        },

        closeEditEventForm() {
            this.editEventForm = false
            this.formContent = {}
            this.modal = false
        },

        saveEditEventForm() {
            let newSlots = JSON.stringify(this.formContent.slots)
            let newMail = JSON.stringify(this.formContent.mail)
            let newApi = JSON.stringify(this.formContent.api)

            logger.debug("sendEditEventForm", this.formContent)

            API.graphql(graphqlOperation(updateEvent, {
              id: this.$route.params.id,
              mail: newMail,
              api: newApi,
              name: this.formContent.name,
              datum: this.formContent.datum,
              description: this.formContent.description,
              slots: newSlots
            })).then(res => {
                if(res.data && res.data.updateClient) {
                  logger.debug('graphql update', res)
                }
              })
              .catch(err => { 
                logger.debug('graphql error', err)
            })
            
            this.editEventForm = false
            this.modal = false
        },

        openNewUserForm(key, slot) {
            logger.debug("openNewUserForm")
            this.formContent = JSON.parse(JSON.stringify(slot))
            this.formContent.token = UUIDGeneratorBrowser()
            this.newUserForm = key
            this.modal = true
        },

        closeNewUserForm() {
            this.newUserForm = -1
            this.formContent = {}
            this.modal = false
        },

        saveNewUserForm() {
            let slots = JSON.parse(this.event.slots)

            this.formContent.mail = JSON.stringify(this.formContent.mail)
            this.formContent.api = JSON.stringify(this.formContent.api)
            slots[this.newUserForm]= this.formContent

            let outSlots = JSON.stringify(slots)

            API.graphql(graphqlOperation(updateEventSlots, {
              id: this.$route.params.id,
              slots: outSlots,
            })).then(res => {
                if(res.data && res.data.updateClient) {
                  logger.debug('graphql update', res)
                  this.newUserForm = null
                  this.modal = false
                }
              })
              .catch(err => logger.debug('graphql error', err))

            this.newUserForm = -1
            this.modal = false
        },

        /* calls from hv api wortmeldeliste */
        callFromAPI(e) {
            this.modal = true
            this.selectedSlot = -1
            this.apiObj = e
        },

        /* select meeting for api call */
        selectSlot(key) {
            if(JSON.parse(this.event.slots)[key].token === "")
                this.selectedSlot = key
        },

        /* press close on meetingroom selector popup */
        closeSelectRoom() {
            this.selectedSlot = -2
            this.modal = false
        },

        /* send meeting invitation to api */
        sendAPIinvitation() {
            console.log(this.apiObj)

            // safe data to database
            let slots = JSON.parse(this.event.slots)

            slots[this.selectedSlot].name = this.apiObj.wortmeldung.name
            slots[this.selectedSlot].username = this.apiObj.wortmeldung.name
            slots[this.selectedSlot].token = UUIDGeneratorBrowser()
            slots[this.selectedSlot].email = this.apiObj.wortmeldung.email
            slots[this.selectedSlot].wmid = this.apiObj.wortmeldung.id
            slots[this.selectedSlot].status = "invited"

            let outSlots = JSON.stringify(slots)

            API.graphql(graphqlOperation(updateEventSlots, {
              id: this.$route.params.id,
              slots: outSlots,
            })).then(res => {
                if(res.data && res.data.updateClient) {
                  logger.debug('graphql update', res)
                  this.newUserForm = null
                  this.modal = false
                }
              })
              .catch(err => logger.debug('graphql error', err))

              // add entry to wortmelde-status-liste
              if(this.event.api && this.event.api.breukel && this.event.api.breukel.gesid) {

                API.graphql(graphqlOperation(createBWMstatus, {
                        eingabeid: slots[this.selectedSlot].wmid,
                        gesellschaftid: this.event.api.breukel.gesid,
                        wmstatus: slots[this.selectedSlot].status,
                        timestamp: new Date()
                    })).then(res => {
                        if(res.data) {
                        logger.debug('graphql create', res)
                        }
                    })
                    .catch(err => {
                        logger.debug('graphql create error, try update', err)
                        API.graphql(graphqlOperation(updateBWMstatus, {
                            eingabeid: this.eventslot.wmid,
                            gesellschaftid: this.event.api.breukel.gesid,
                            wmstatus: slots[this.selectedSlot].status,
                            timestamp: new Date()
                        })).then(res => {
                            if(res.data) {
                            logger.debug('graphql update', res)
                            }
                        }).catch(err => logger.debug('graphql update error', err))
                    })
                }
            // send back meeting url
            this.apiObj.callback(this.userMeetingLink(this.selectedSlot, slots[this.selectedSlot]))

            this.apiObj = null
            this.closeSelectRoom()
        },

        userMeetingLink(key, slot) {
            let token = {
                event: this.$route.params.id,
                slot: ""+key,
                token: slot.token
            }
            
            let tokenB64 = encodeURI(btoa(JSON.stringify(token)))
            return 'https://videocall.streaming-solution.de/?token=' + tokenB64
        },

        modifySlotMetadata(data) {
            let str = data.id
            let eventid = str.slice(0, 36)
            if(eventid == this.event.id) {
                let n = str.lastIndexOf('-')
                let slotindex = str.substring(n + 1)
                
                Vue.set(this.slotstatus, slotindex, data)
            }
        },

        removeSlotMetadata(data) {
            let str = data.id
            let eventid = str.slice(0, 36)
            if(eventid == this.event.id) {
                let n = str.lastIndexOf('-')
                let slotindex = str.substring(n + 1)
                
                Vue.delete(this.slotstatus, slotindex)
            }
        }
    },

    created() {
      this.loadData()
    },

    mounted() {
        // Subscribe to change of event
        this.subscriptions.push = API.graphql(
            graphqlOperation(onUpdateEvent)
        ).subscribe({
            next: (action) => {
                if(action.value && action.value.data && action.value.data.onUpdateVideocallEvent) {
                    // if this update is for my event...
                    if(action.value.data.onUpdateVideocallEvent.id == this.$route.params.id) {
                        let updatedEvent = action.value.data.onUpdateVideocallEvent

                        this.event.name = updatedEvent.name
                        this.event.slots = updatedEvent.slots

                        updatedEvent.mail = JSON.parse(updatedEvent.mail)
                        updatedEvent.api = JSON.parse(updatedEvent.api)

                        this.event.mail.subject = updatedEvent.mail.subject
                        this.event.mail.cc = updatedEvent.mail.cc
                        this.event.mail.bcc = updatedEvent.mail.bcc
                        this.event.mail.body = updatedEvent.mail.body
                        this.event.mail.html = updatedEvent.mail.html

                        this.event.api = updatedEvent.api
                    }

                    
                }
            },
            error: (error) => logger.debug(error)
        })

        this.subscriptions.push = API.graphql(
            graphqlOperation(onCreateVideocallMeeting)
        ).subscribe({
            next: (action) => {
                if(action.value && action.value.data && action.value.data.onCreateVideocallMeeting) {
                    this.modifySlotMetadata(action.value.data.onCreateVideocallMeeting)
                }
            },
            error: (error) => logger.debug(error)
        })

        this.subscriptions.push = API.graphql(
            graphqlOperation(onUpdateVideocallMeeting)
        ).subscribe({
            next: (action) => {
                if(action.value && action.value.data && action.value.data.onUpdateVideocallMeeting) {
                    this.modifySlotMetadata(action.value.data.onUpdatexVideocallMeeting)
                }
            },
            error: (error) => logger.debug(error)
        })

        this.subscriptions.push = API.graphql(
            graphqlOperation(onDeleteVideocallMeeting)
        ).subscribe({
            next: (action) => {
                if(action.value && action.value.data && action.value.data.onDeleteVideocallMeeting) {
                    this.removeSlotMetadata(action.value.data.onDeleteVideocallMeeting)
                }
            },
            error: (error) => logger.debug(error)
        })
    },

    beforeUnmount() {
        // unsubscribe all subscriptions
        logger.debug("unsubscribe everything");
        for(var i = 0 ; i < this.subscriptions.length ; i++)
            this.subscriptions[i].unsubscribe()
    },

    components: {
        LoadingWheel,
        EventSlot,
        NotificationTypeDropdown,
        SpeakerList
    },

    $el: 'root'
  }
</script>

<style scoped>
    .ui.card>.content, .ui.cards>.disabled.card>.content {
        background: #ccc;
    }

    .ui.card>.content, .ui.cards>.selected.card>.content {
        background: #e1f7f7;
    }
</style>