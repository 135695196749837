<template>
  <div class="ui two column centered stackable grid">
    <div class="column">
    <h2 class="ui image header">
      <div class="content">
        <i class="key icon"></i> Anmelden
      </div>
    </h2>

    <form v-if="!confirmView && !newPasswordView && !forgotPassword" class="ui large form" v-on:submit.prevent="signIn">
      <div class="ui stacked secondary segment">
        <div class="field">
          <div class="ui left icon input">
            <i class="user icon"></i>
            <input type="text" v-model="username" autocomplete="username" placeholder="Benutzername" autofocus>
          </div>
        </div>
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="password" v-model="password" autocomplete="current-password" placeholder="Passwort">
          </div>
        </div>
        <button class="ui fluid large submit button" v-on:click="signIn" :disabled="!username || !password">anmelden</button>
      </div>
    </form>

    <form v-if="forgotPassword && !forgotPasswordSubmit" class="ui large form" v-on:submit.prevent="resetPassword">
      <div class="ui stacked secondary segment">
        <div class="field">
          <div class="ui left icon input">
            <i class="user icon"></i>
            <input type="text" v-model="username" autocomplete="username" placeholder="Benutzername" autofocus>
          </div>
        </div>
        <button class="ui fluid large submit button" v-on:click="resetPassword" :disabled="!username">Passwort zurücksetzen</button>
      </div>
    </form>

    <form v-if="forgotPassword && forgotPasswordSubmit" class="ui large form" v-on:submit.prevent="resetPasswordSubmit">
      <div class="ui stacked secondary segment">
        <div class="field">
          <div class="ui left icon input">
            <i class="user icon"></i>
            <input type="text" v-model="username" autocomplete="username" placeholder="Benutzername" autofocus>
          </div>
        </div>
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input v-model="code" placeholder="Code" autocomplete="off" />
          </div>
        </div>
        <br>
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="password" v-model="newpassword1" autocomplete="new-password" placeholder="neues Password" autofocus>
          </div>
        </div>
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="password" v-model="newpassword2" autocomplete="new-password" placeholder="Passwort wiederholen">
          </div>
        </div>
        <button class="ui fluid large submit button" v-on:click="resetPasswordSubmit" :disabled="!username || !code || !newpassword1 || !newpassword2 || newpassword1 != newpassword2">Passwort zurücksetzen</button>
      </div>
    </form>

    <form v-if="newPasswordView" class="ui large form" v-on:submit.prevent="changePassword">
      <div class="ui stacked secondary segment">
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="password" v-model="newpassword1" autocomplete="new-password" placeholder="neues Password" autofocus>
          </div>
        </div>
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="password" v-model="newpassword2" autocomplete="new-password" placeholder="Passwort wiederholen">
          </div>
        </div>
        <button class="ui fluid large submit button" v-on:click="changePassword" :disabled="!newpassword1 || !newpassword2 || newpassword1 != newpassword2">Passwort ändern</button>
      </div>
    </form>

    <div v-if="confirmView">
      <div class="ui stacked secondary segment">
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input v-model="code" placeholder="Code" autocomplete="off" />
          </div>
        </div>
        <button class="ui fluid large submit button" v-on:click="confirm" :disabled="!code">bestätigen</button>
      </div>
    </div>
    
    <div v-if="error" class="ui error message">{{ error }}</div>
    
    <div class="ui" style="margin: 1em 0; text-align: right;">
      <a href="#" v-if="!forgotPassword" v-on:click.prevent="forgotPassword = true">Passwort zurücksetzen</a>
      <a href="#" v-else v-on:click.prevent="forgotPassword = false">zurück zum Login</a>
    </div>
  </div>

  <div class="ui vertical footer segment">
    <div class="ui center aligned container">
      <div class="ui inverted section divider"></div>
      <div class="ui horizontal inverted small divided link list">
        v{{ require('../../../package.json').version }}
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import { Auth, Logger } from 'aws-amplify'
  import _ from 'lodash'
  import AmplifyStore from '../AmplifyStore'

  const logger = new Logger('SignInComp')

  export default {
    name: 'SignIn',
    data () {
      return {
          username: '',
          password: '',

          newpassword1: '',
          newpassword2: '',

          user: null,
          newPasswordView: false,
          confirmView: false,
          code: '',

          forgotPassword: false,
          forgotPasswordSubmit: false,
          forgotInfo: '',

          error: '',
          info: ''
      }
    },
    methods: {
      signIn: function() {
        const that = this
        Auth.signIn(this.username, this.password)
          .then(user => {
            logger.debug('sign in success', user)
            AmplifyStore.commit('setUser', user)
            return user
          })
          .then(user => {
            this.error = ''
            that.user = user
            if (user.challengeName === 'SMS_MFA') {
              that.confirmView = true
              return
            }
            this.checkUser()
          })
          .catch(err => this.setError(err))
      },

      changePassword: function() {
        const user = this.user
        const newpassword = this.newpassword1

        if(this.newpassword1 != this.newpassword2) { 
          this.setError('passwords do not match')
          return 
        }

        Auth.completeNewPassword(user, newpassword)
          .then(data => {
            logger.debug('...password changed', data)
            this.$router.push('/')
          })
          .catch(err => this.setError(err))
      },

      resetPassword: function() {
        Auth.forgotPassword(this.username)
          .then(data => {
            logger.debug('forgotPassword', data)

            this.forgotPasswordSubmit = true
            this.code = ''
            this.error = ''

            this.setInfo('')
          })
          .catch(err => this.setError(err))
      },

      resetPasswordSubmit: function() {

        if(this.newpassword1 != this.newpassword2) { 
          this.setError('passwords do not match')
          return
        }

        Auth.forgotPasswordSubmit(this.username, this.code, this.newpassword1)
          .then(data => {
            logger.debug('forgotPassword', data)

            this.forgotPasswordSubmit = false
            this.forgotPassword = false
            this.code = ''
            this.error = ''
            this.info = ''
          })
          .catch(err => this.setError(err))
      },

      checkUser: function() {
        const user = this.user
        if (!user) { return }

        /* if user has to change password on login */
        if(user.challengeName == "NEW_PASSWORD_REQUIRED") {
          this.newPasswordView = true
          return
        }

        logger.debug('...verifying user', user)
        AmplifyStore.commit('setUserGroups', user.signInUserSession.accessToken.payload["cognito:groups"])

        Auth.verifiedContact(user)
          .then(data => {
            logger.debug('verify result', data)
            AmplifyStore.commit('setUserVerification', data)
            if (!_.isEmpty(data.verified)) {
              this.$router.push('/')
            } else {
              this.$router.push('/auth/verifyContact')
            }
          })
      },

      confirm: function() {
        Auth.confirmSignIn(this.user, this.code)
          .then(() => {
            this.$router.push('/')
          })
          .catch(err => this.setError(err))
      },

      setError: function(err) {
          this.error = err.message || err
      },

      setInfo: function(info) {
        this.info = info
      }
    }
  }
</script>
