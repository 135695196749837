import Vue from 'vue'
import Router from 'vue-router'

import Events from '../views/Events.vue'
import Event from '../views/Event.vue'
import LowerThird from '../views/LowerThird.vue'
import Wortmeldeliste from '../views/Wortmeldeliste.vue'

import { AuthRouter, AuthFilter } from '../amplify'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'events',
      component: Events,
      meta: {
        breadcrumb: [
          { name: 'Veranstaltungen' }
        ]
      }
    },
    {
      path: '/event/:id',
      name: 'event',
      component: Event,
      meta: {
        breadcrumb: [
          { name: 'Veranstaltungen', link: '/' },
          { name: 'Veranstaltung' }
        ]
      }
    },
    {
      path: '/lowerthird/:id',
      name: 'lowerthird',
      component: LowerThird,
      meta: {
        breadcrumb: [
          { name: 'Veranstaltungen', link: '/' },
          { name: 'Bauchbinden' }
        ]
      }
    },
    {
      path: '/wortmeldeliste/:id',
      name: 'wortmeldeliste',
      component: Wortmeldeliste,
      meta: {
        breadcrumb: [
          { name: 'Veranstaltungen', link: '/' },
          { name: 'Wortmeldeliste' }
        ]
      }
    },
    AuthRouter
  ]
})

router.beforeEach(AuthFilter)

export default router
