<template>
    <div class="view-event">
        <loading-wheel v-if="loading"></loading-wheel>

        <div v-else class="flexcontainer">
            <SpeakerList :breukel="event.api.breukel" :meetings="JsonEventSlots" :fullscreenMode="true" :viewOnly="true"></SpeakerList>
        </div>
    </div>
</template>

<script>
  import Vue from 'vue' // eslint-disable-line no-unused-vars

  import { Logger } from 'aws-amplify'
  import { API, graphqlOperation } from '@aws-amplify/api'

  import { getEvent } from '../graphql/queries'
  import { onUpdateEvent } from '../graphql/subscriptions'

  const logger = new Logger('Wortmeldeliste.vue')

  import LoadingWheel from '../components/LoadingWheel'
  import SpeakerList from '../components/SpeakerList'

  export default {
    data() {
      return {
        subscriptions: [],
        loading: true,
        event: null,
      }
    },

    computed: {
        JsonEventSlots() {
            return JSON.parse(this.event.slots);
        }
    },

    methods: {
        loadData() {
            this.loading = true

            logger.debug("route id", this.$route.params.id)
            
            API.graphql(graphqlOperation(getEvent,  { id: this.$route.params.id }))
            .then(res => {
                if(res.data && res.data.getVideocallEvent) {
                    // parse mail data
                    res.data.getVideocallEvent.mail = JSON.parse(res.data.getVideocallEvent.mail)
                    res.data.getVideocallEvent.api = JSON.parse(res.data.getVideocallEvent.api)
                    this.event = res.data.getVideocallEvent
                }
                this.modified = false
                this.loading = false
            })
            .catch(err => logger.debug('graphql error', err))
        },
    },

    created() {
        this.loadData()
    },

    mounted() {
        // Subscribe to change of event
        this.subscriptions.push = API.graphql(
            graphqlOperation(onUpdateEvent)
        ).subscribe({
            next: (action) => {
                if(action.value && action.value.data && action.value.data.onUpdateVideocallEvent) {
                    if(action.value.data.onUpdateVideocallEvent.id == this.$route.params.id) {
                        // update local data
                        let updatedEvent = action.value.data.onUpdateVideocallEvent
                        if(this.$route.params.id == this.event.id) {
                            this.event.name = updatedEvent.name
                            this.event.slots = updatedEvent.slots

                            updatedEvent.mail = JSON.parse(updatedEvent.mail)
                            updatedEvent.api = JSON.parse(updatedEvent.api)

                            this.event.mail.subject = updatedEvent.mail.subject
                            this.event.mail.cc = updatedEvent.mail.cc
                            this.event.mail.bcc = updatedEvent.mail.bcc
                            this.event.mail.body = updatedEvent.mail.body
                            this.event.mail.html = updatedEvent.mail.html

                            this.event.api = updatedEvent.api
                        }
                    }
                }
            },
            error: (error) => logger.debug(error)
        })
    },
    
    beforeUnmount() {
        // unsubscribe all subscriptions
        logger.debug("unsubscribe everything");
        for(var i = 0 ; i < this.subscriptions.length ; i++)
            this.subscriptions[i].unsubscribe()
    },

    components: {
        LoadingWheel,
        SpeakerList
    },

    $el: 'root'
  }
</script>


<style scoped>
.flexcontainer {
    z-index: 999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #fff;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: 3vh 3vw;
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>