<template>
    <div class="view-event">
        <loading-wheel v-if="loading"></loading-wheel>

        <div v-else class="flexcontainer"><!-- headline -->
            <LowerThirdElement v-for="(slot, key) in JSON.parse(event.slots)" :key="key" :eventslotid="key" :eventslot="slot"></LowerThirdElement>
        </div>
    </div>
</template>

<script>
  import Vue from 'vue' // eslint-disable-line no-unused-vars

  import { Logger } from 'aws-amplify'
  import { API, graphqlOperation } from '@aws-amplify/api'
  import { promiseWithTimeout } from '../promise-timeout'

  import { getEvent, listMeetings } from '../graphql/queries'
  import { onUpdateEvent, onCreateVideocallMeeting, onUpdateVideocallMeeting } from '../graphql/subscriptions'

  import LoadingWheel from '../components/LoadingWheel'
  import LowerThirdElement from '../components/LowerThirdElement'

  const logger = new Logger('LowerThird.vue')

  export default {
    data() {
      return {
        event: null,
        slotstatus: [],
        meetings: null,
        modified: false, /* wenn daten verändert, speichern knopf aktivieren */
        loading: true,
        modal: false,
        editEventForm: false,
        newUserForm: -1,
        formContent: {},
        subscriptions: []
      }
    },

    computed: {
    },

    methods: {
        loadData() {
            this.loading = true

            logger.debug("route id", this.$route.params.id)

            API.graphql(graphqlOperation(getEvent,  { id: this.$route.params.id }))
            .then(res => {
                if(res.data && res.data.getVideocallEvent) {
                    // parse mail data
                    res.data.getVideocallEvent.mail = JSON.parse(res.data.getVideocallEvent.mail)
                    this.event = res.data.getVideocallEvent
                }
                this.modified = false
                this.loading = false
            })
            .catch(err => logger.debug('graphql error', err))

            // get metadata for meetingslots
            let doIt = promiseWithTimeout(15000, API.graphql(graphqlOperation(listMeetings)))
  
            // Wait for the promise to get resolved
            doIt.then(res => {
                if(res.data && res.data.listVideocallMeetings) {
                    let objs = res.data.listVideocallMeetings.items

                    for(var i = 0 ; i < objs.length ; i++)
                        this.modifySlotMetadata(objs[i])
                } else 
                    this.slotstatus = []
            })

            // Wait for the promise to get rejected or timed out
            doIt.catch(err => {
                logger.debug('graphql error', err)
                this.loading = false
                this.nodata = true
            })
            
        },

        onChangeNotificationType(newValue) {
            this.formContent.mail.notificationType = newValue
            logger.debug(this.formContent)
        },

        modifySlotMetadata(data) {
            let str = data.id
            let eventid = str.slice(0, 36)
            if(eventid == this.event.id) {
                var n = str.lastIndexOf('-')
                var slotindex = str.substring(n + 1)
                
                Vue.set(this.slotstatus, slotindex, data)
            }
        }
    },

    created() {
      this.loadData()
    },

    mounted() {
        // Subscribe to change of event
        this.subscriptions.push = API.graphql(
            graphqlOperation(onUpdateEvent)
        ).subscribe({
            next: (action) => {
                if(action.value && action.value.data && action.value.data.onUpdateVideocallEvent) {
                    // update local data
                    let updatedEvent = action.value.data.onUpdateVideocallEvent
                    if(this.$route.params.id == this.event.id) {
                        this.event.name = updatedEvent.name
                        this.event.slots = updatedEvent.slots

                        updatedEvent.mail = JSON.parse(updatedEvent.mail)

                        this.event.mail.subject = updatedEvent.mail.subject
                        this.event.mail.cc = updatedEvent.mail.cc
                        this.event.mail.bcc = updatedEvent.mail.bcc
                        this.event.mail.body = updatedEvent.mail.body
                        this.event.mail.html = updatedEvent.mail.html
                    }
                }
            },
            error: (error) => logger.debug(error)
        })

        this.subscriptions.push = API.graphql(
            graphqlOperation(onCreateVideocallMeeting)
        ).subscribe({
            next: (action) => {
                if(action.value && action.value.data && action.value.data.onCreateVideocallMeeting) {
                    this.modifySlotMetadata(action.value.data.onCreateVideocallMeeting)
                }
            },
            error: (error) => logger.debug(error)
        })

        this.subscriptions.push = API.graphql(
            graphqlOperation(onUpdateVideocallMeeting)
        ).subscribe({
            next: (action) => {
                if(action.value && action.value.data && action.value.data.onUpdateVideocallMeeting) {
                    this.modifySlotMetadata(action.value.data.onCreateVideocallMeeting)
                }
            },
            error: (error) => logger.debug(error)
        })
    },

    beforeUnmount() {
        // unsubscribe all subscriptions
        logger.debug("unsubscribe everything");
        for(var i = 0 ; i < this.subscriptions.length ; i++)
            this.subscriptions[i].unsubscribe()
    },

    components: {
        LoadingWheel,
        LowerThirdElement,
    },

    $el: 'root'
  }
</script>


<style scoped>
.flexcontainer {
    z-index: 999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #000;
    color: #fff;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: stretch;
}
</style>