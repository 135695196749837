// eslint-disable
// this is an auto generated file. This will be overwritten

export const createEvent = `mutation CreateEvent($name: String!, $datum: AWSDate!) {
    createVideocallEvent(input: { name: $name, datum: $datum }) {
      id
      name
      description
      datum
      mail
      api
      slots
    }
  }
  `;
  
  export const updateEvent = `mutation UpdateEvent($id: ID!, $name: String, $datum: AWSDate, $description: String, $api: AWSJSON, $mail: AWSJSON, $slots: AWSJSON) {
    updateVideocallEvent(input: {id: $id, name: $name, datum: $datum, description: $description, api: $api, mail: $mail, slots: $slots}) {
      id
      name
      description
      datum
      mail
      api
      slots
    }
  }
  `;

  export const updateEventArchive = `mutation UpdateEvent($id: ID!, $archive: Boolean) {
    updateVideocallEvent(input: {id: $id, archive: $archive}) {
      id
      name
      description
      datum
      mail
      api
      slots
      archive
    }
  }
  `;

  export const updateEventSlots = `mutation UpdateEvent($id: ID!, $slots: AWSJSON) {
    updateVideocallEvent(input: {id: $id, slots: $slots}) {
      id
      name
      description
      datum
      mail
      api
      slots
    }
  }
  `; 
  
  export const deleteEvent = `mutation DeleteEvent($id: ID!) {
    deleteVideocallEvent(input: { id: $id }) {
      id
    }
  }
  `;

  export const createVideocallActivityLog = `mutation createVideocallActivityLog($slotid: String!, $slottoken: String!, $action: String!, $info: String) {
    createVideocallActivityLog(input: {action: $action, info: $info, slotid: $slotid, slottoken: $slottoken}) {
      action
      info
      slotid
      slottoken
      timestamp
    }
  }
  `;
  
  export const createBWMstatus = `mutation CreateVideocallBreukelwmstatus($eingabeid: String!, $gesellschaftid: String!, $timestamp: AWSDateTime, $wmstatus: String) {
    createVideocallBreukelwmstatus(input: { eingabeid: $eingabeid, gesellschaftid: $gesellschaftid, timestamp: $timestamp, status: $wmstatus }) {
      eingabeid
      gesellschaftid
      status
      timestamp
    }
  }
  `;

  export const updateBWMstatus = `mutation UpdateVideocallBreukelwmstatus($eingabeid: String!, $gesellschaftid: String!, $timestamp: AWSDateTime, $wmstatus: String) {
    updateVideocallBreukelwmstatus(input: { eingabeid: $eingabeid, gesellschaftid: $gesellschaftid, timestamp: $timestamp, status: $wmstatus }) {
      eingabeid
      gesellschaftid
      status
      timestamp
    }
  }
  `;