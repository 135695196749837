import AuthView from './views/Auth'

import AmplifyStore from './AmplifyStore'
import * as Components from './components'

import { Auth, Logger } from 'aws-amplify'

const logger = new Logger('AuthRouter')

const AuthRouter = {
  path: '/auth',
  name: 'auth',
  component: AuthView,
  children: [
    {
      path: 'signIn',
      name: 'auth_SignIn',
      component: Components.SignIn
    }
  ]
}

const AuthFilter = (to, from, next) => {
  logger.debug('before routing ', to, from)
  Auth.currentAuthenticatedUser()
    .then(user => {
      logger.debug('...has user', user)

      // set groups
      AmplifyStore.commit('setUserGroups', user.signInUserSession.accessToken.payload["cognito:groups"])

      AmplifyStore.commit('setUser', user)
      Auth.currentCredentials()
        .then(credentials => {
          logger.debug('...current credentials', credentials)
          AmplifyStore.commit('setUserId', credentials.identityId)
        })
        .catch(err => logger.debug('get current credentials err', err))
      next()
    })
    .catch(err => {
      logger.debug('...no user error', err)
      AmplifyStore.commit('setUser', null)
      if (!to.name.startsWith('auth')) {
        next('/auth/signIn')
      } else {
        next()
      }
    })
}

export default AuthRouter
export { AuthFilter }
