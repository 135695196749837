// eslint-disable
// this is an auto generated file. This will be overwritten

export const onUpdateEvent = `subscription OnUpdateVideocallEvent {
    onUpdateVideocallEvent {
      id
      name
      mail
      api
      slots
    }
  }`;

export const onCreateVideocallMeeting = `subscription OnCreateVideocallMeeting {
    onCreateVideocallMeeting {
      id
      status
      user
    }
  }`;

export const onUpdateVideocallMeeting = `subscription OnUpdateVideocallMeeting {
    onUpdateVideocallMeeting {
      id
      status
      user
    }
  }`;

  export const onDeleteVideocallMeeting = `subscription OnDeleteVideocallMeeting {
    onDeleteVideocallMeeting {
      id
      status
      user
    }
  }`;

  export const onCreateVideocallBreukelwmstatus = `subscription OnCreateVideocallBreukelwmstatus($gesellschaftid: String!) {
    onCreateVideocallBreukelwmstatus(gesellschaftid: $gesellschaftid) {
      eingabeid
      gesellschaftid
      status
      timestamp
    }
  }`;

  export const onUpdateVideocallBreukelwmstatus = `subscription OnUpdateVideocallBreukelwmstatus($gesellschaftid: String!) {
    onUpdateVideocallBreukelwmstatus(gesellschaftid: $gesellschaftid) {
      eingabeid
      gesellschaftid
      status
      timestamp
    }
  }`;