<template>
    <div class="ui fluid selection dropdown">
        <input type="hidden" name="mailtype" v-model="notificationType">
        <i class="dropdown icon"></i>
        <div class="default text">Benachrichtigungsart wählen</div>
        <div class="menu">
            <div class="item" data-value="email" v-on:click="setValue('email')">
                <i class="mail icon"></i>
                Einladung per eMail via AWS SNS
            </div>
            <div class="item" data-value="mailto" v-on:click="setValue('mailto')">
                <i class="external alternate icon"></i>
                Einladung per eMail via externem eMail Client
            </div>
            <div class="item" data-value="breukelapi" v-on:click="setValue('breukelapi')">
                <i class="bell icon"></i>
                Einladung per Breukel API
            </div>
        </div>
    </div>
</template>

<script>
import { Logger } from 'aws-amplify'

const logger = new Logger('NotificationTypeDropdown.vue')

export default {
    props: {
      notificationType: String,
    },

    data() {
        return {}
    },

    methods: {
      setValue(val) {
        logger.debug('set notification type', val)
        this.$emit('onChange', val)
      },
    },

    created() {
    },

    mounted() {
      // init dropdown
      this.$nextTick(() => { // do in nextTick for fixing time issues
        $(this.$el).dropdown() // eslint-disable-line no-undef
      })
    },

    updated() {
        logger.debug("updated()");
      // init dropdown
      this.$nextTick(() => { // do in nextTick for fixing time issues
        $(this.$el).dropdown() // eslint-disable-line no-undef
      })
    }
}
</script>