import Vue from 'vue'
import 'semantic-ui-css/semantic.min.css'

import { Amplify, Logger } from 'aws-amplify'
import aws_exports from './aws-exports'

Amplify.configure(aws_exports)
Logger.LOG_LEVEL = 'DEBUG'

import App from './App.vue'

import router from './router'

Vue.config.productionTip = false

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
