<template>
    <div class="item" :class="{ fullscreen: fullscreenMode }">
        <div class="right floated content" v-if="!viewOnly && obj.obj.wortmeldung">
            <button class="ui compact gray icon button" v-if="!inMeeting && obj.obj.konferenzid" v-on:click="call()">
              <i class="call icon"></i> erneut anrufen
            </button>
            <button class="ui compact red icon button" v-if="!inMeeting && obj.obj.konferenzid || inMeeting" v-on:click="remove()">
              <i class="times icon"></i> anruf beenden
            </button>
            <button class="ui compact red icon button" v-on:click="remove()" v-if="!inMeeting && !obj.obj.konferenzid">
              <i class="times icon"></i> zurückziehen
            </button>
            <button class="ui compact green icon button" v-if="!inMeeting && !obj.obj.konferenzid" v-on:click="call()">
              <i class="call icon"></i> anrufen
            </button>
            <button class="ui compact gray icon button" v-if="inMeeting && inMeeting.status == 'invited'" v-on:click="call()">
              <i class="call icon"></i> erneut anrufen
            </button>
            <button class="ui black compact disabled button" v-if="inMeeting">
                <i class="icon laptop"></i>
                {{ inMeetingNo }}
            </button>
        </div>
        <div class="ui circular middle aligned big label" v-if="obj.obj.wortmeldung && inMeeting" :class="statusColor[inMeeting.status]"><i class="icon circleicon" :class="statusIcon[inMeeting.status]"></i></div>
        <div class="ui circular middle aligned big label black" v-else-if="obj.status == 'done' || obj.status == 'live'"><i class="icon circleicon check"></i></div>
        <div class="ui circular middle aligned big label" v-else-if="obj.obj.wortmeldung">{{ obj.nr }}</div>
        <div class="ui circular middle aligned big label black" v-else><i class="icon circleicon times"></i></div>
        
        <div class="content">
            <div class="header">{{ obj.name }}</div>
            <div class="description">
                <div v-if="inMeeting"><i class="icon" v-if="!fullscreenMode" :class="statusIcon[inMeeting.status] + ' ' + statusColor[inMeeting.status] + ' '"></i>
                {{ statusText[inMeeting.status] }}</div>
                <div v-else-if="!fullscreenMode">{{ wmzeit }}</div>
                <div v-if="!fullscreenMode"><i class="grey mail icon"></i> {{  obj.email }} <i class="grey phone icon"></i> {{  obj.phone }}</div>
                <!--<pre>{{ inMeeting }}</pre>-->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import { Logger } from 'aws-amplify'
const logger = new Logger('SpeakerListItem.vue')

moment.locale('de')

export default {
    props: {
      obj: Object,
      meetings: Array,
      viewOnly: Boolean,
      fullscreenMode: Boolean
    },

    data() {
    return {
        statusIcon: {
            'invited':  'paper plane',
            'connecting': 'hourglass start',
            'calling':  'phone',
            'checking': 'search',
            'ready':    'user',
            'failed':   'times',
            'done':     'check',
            'live':     'microphone'
        },

        statusText: {
            'invited':  'Einladung versendet',
            'connecting': 'Verbindung wird aufgebaut',
            'calling':  'im Meeting',
            'checking': 'in Prüfung',
            'ready':    'bereit',
            'failed':   'Vorpüfung fehlgeschlagen',
            'done':     'erledigt',
            'live':     'spricht gerade'
        },

        statusColor: {
            'invited':  'grey',
            'connecting': 'grey',
            'calling':  'yellow',
            'checking': 'orange',
            'ready':    'green',
            'failed':   'red',
            'done':     'black',
            'live':     'teal'
        }
    }
    },

    computed: {
        wmzeit() {
            return moment(this.obj.obj.eingabezeit).format("DD.MM.YY hh:mm:ss")
        },

        inMeeting() {
            return _.find(this.meetings, ['wmid', this.obj.id])
        },

        inMeetingNo() {
            return _.findIndex(this.meetings, ['wmid', this.obj.id])+1
        }
    },

    methods: {
        remove() {
            fetch(this.obj.deleteurl).then(response => console.log(response))
        },

        call() {
            let self = this
            var e = {
                wortmeldung: this.obj,
                callback: function(url) { 
                    // breukel portal feedback
                    fetch(self.obj.inviteurl + encodeURIComponent(url)).then(response => logger.debug(response))
                }
            }
            this.$emit("doCall",e)
        }
    }

}
</script>

<style scoped>
    .item.fullscreen {
        font-size: 6vh;
    }

    .item.fullscreen .ui.big.label, .item.fullscreen .ui.big.labels .label {
        font-size: 3rem;
    }

    .ui.label>.icon.circleicon {
        min-width: 1.2rem;
        height: 1.2rem;
        line-height: 1.2rem;
        margin: 0rem;
        padding: 0rem;
        text-align: center;
    }
    
    .ui.list .list>.item>.ui.circular.label, .ui.list>.item>.ui.circular.label {
        float: left;
        margin-right: 0.7em;
    }
</style>